<template>
  <div>
    <div v-if="hasUserData">
      <b-container v-if="paymentMethod === 'payoneer'">
        <b-row
          align-content="center"
          align-h="center"
          cols="3"
          class="mt-3 mb-3 py-3"
        >
          <b-col
            cols="12"
            :md="hasStepGuide ? 9 : 6"
            class="text-center"
          >
            <h1>
              <img
                :src="payoneerLogo"
                width="52px"
                alt="Payoneer"
              >
              Payoneer</h1>

            <b-img
              class="m-2"
              fluid
              :src="require('@/assets/images/icons/icon-error.svg')"
              alt="Error"
              width="125"
            />

            <h3 class="mb-3">
              Failed Payment!
            </h3>

            <div class="card">
              <div class="d-flex flex-row justify-content">
                <span class="py-1 pl-1">
                  Error:
                </span>
                <span class="text-danger p-1 text-left">
                  {{ errorMessage }}
                </span>
              </div>
            </div>

            <div
              class="action mt-2 d-flex flex-column flex-sm-row align-items-center action-wrap"
              :class="hasStepGuide ? 'justify-content-center' : 'justify-content-between'"
            >
              <btn-loading
                variant-convert="outline-dark"
                class="btn-sm-block"
                @click="handleRedirectRouter(tab='payoneer')"
              >
                Recharge
              </btn-loading>

              <btn-loading
                v-if="!hasStepGuide"
                variant-convert="primary"
                class="btn-block mb-1 mt-1 ml-sm-2"
                @click="handleShowAdsAccount"
              >
                {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
              </btn-loading>
            </div>

          </b-col>
        </b-row>
      </b-container>

      <b-container v-else-if="paymentMethod === 'airWallexAuto'">
        <b-row
          align-content="center"
          align-h="center"
          cols="3"
          class="mt-3 mb-3 py-3"
        >
          <b-col
            cols="12"
            :md="hasStepGuide ? 9 : 6"
            class="text-center"
          >
            <h1>
              <img
                :src="airwallexLogo"
                width="52px"
                alt="Airwallex"
              >
              Airwallex</h1>

            <b-img
              class="m-2"
              fluid
              :src="require('@/assets/images/icons/icon-error.svg')"
              alt="Error"
              width="125"
            />

            <h3 class="mb-3">
              Failed Payment!
            </h3>

            <div class="card">
              <div class="d-flex flex-row justify-content">
                <span class="py-1 pl-1">
                  Error:
                </span>
                <span class="text-danger p-1 text-left">
                  {{ errorMessage }}
                </span>
              </div>
            </div>

            <div
              class="action mt-2 d-flex flex-column flex-sm-row align-items-center action-wrap"
              :class="hasStepGuide ? 'justify-content-center' : 'justify-content-between'"
            >
              <btn-loading
                variant-convert="outline-dark"
                class="btn-sm-block btn-recharge"
                @click="handleRedirectRouter(tab='airwallex-new')"
              >
                Recharge
              </btn-loading>

              <btn-loading
                v-if="!hasStepGuide"
                variant-convert="btn-submit"
                class="btn-block mb-1 mt-1 ml-sm-2"
                @click="handleShowAdsAccount"
              >
                {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
              </btn-loading>
            </div>

          </b-col>
        </b-row>
      </b-container>

      <b-container v-else-if="paymentMethod === 'payfast'">
        <b-row
          align-content="center"
          align-h="center"
          cols="3"
          class="mt-3 mb-3 py-3"
        >
          <b-col
            cols="12"
            :md="hasStepGuide ? 9 : 6"
            class="text-center"
          >
            <h1>
              <img
                :src="payfastLogo"
                width="52px"
                alt="PayFast"
              >
              PayFast</h1>

            <b-img
              class="m-2"
              fluid
              :src="require('@/assets/images/icons/icon-error.svg')"
              alt="Error"
              width="125"
            />

            <h3 class="mb-3">
              Failed Payment!
            </h3>

            <div class="card">
              <div class="d-flex flex-row justify-content">
                <span class="py-1 pl-1">
                  Error:
                </span>
                <span class="text-danger p-1 text-left">
                  {{ payfastErrorMessage }}
                </span>
              </div>
            </div>

            <div
              class="action mt-2 d-flex flex-column align-items-center action-wrap"
              :class="[{ 'flex-sm-row': !hasStepGuide }, hasStepGuide ? 'justify-content-center' : 'justify-content-between']"
            >
              <btn-loading
                variant-convert="outline-dark"
                class="btn-sm-block btn-recharge"
                @click="handleRedirectRouter('payfast')"
              >
                Recharge
              </btn-loading>

              <btn-loading
                v-if="!hasStepGuide"
                variant-convert="btn-submit"
                class="btn-block mb-1 mt-1 ml-sm-2"
                @click="handleShowAdsAccount"
              >
                {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
              </btn-loading>
            </div>

          </b-col>
        </b-row>
      </b-container>

      <b-container v-else-if="paymentMethod === 'localBank'">
        <b-row
          align-content="center"
          align-h="center"
          cols="3"
          class="mt-3 mb-3 py-3"
        >
          <b-col
            cols="12"
            :md="hasStepGuide ? 9 : 6"
            class="text-center"
          >
            <h1>
              <img
                :src="localBankLogo"
                width="52px"
                alt="Local bank"
              >
              Local bank</h1>

            <b-img
              class="m-2"
              fluid
              :src="require('@/assets/images/icons/icon-error.svg')"
              alt="Error"
              width="125"
            />

            <h3 class="mb-3">
              Failed Payment!
            </h3>

            <div class="card">
              <div class="d-flex flex-row justify-content">
                <span class="py-1 pl-1">
                  Error:
                </span>
                <span class="text-danger p-1 text-left">
                  {{ payfastErrorMessage }}
                </span>
              </div>
            </div>

            <div
              class="action mt-2 d-flex flex-column align-items-center action-wrap"
              :class="[{ 'flex-sm-row': !hasStepGuide }, hasStepGuide ? 'justify-content-center' : 'justify-content-between']"
            >
              <btn-loading
                variant-convert="outline-dark"
                class="btn-sm-block btn-recharge"
                @click="handleRedirectRouter('local-bank')"
              >
                Recharge
              </btn-loading>

              <btn-loading
                v-if="!hasStepGuide"
                variant-convert="btn-submit"
                class="btn-block mb-1 mt-1 ml-sm-2"
                @click="handleShowAdsAccount"
              >
                {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
              </btn-loading>
            </div>

          </b-col>
        </b-row>
      </b-container>

      <b-container v-if="paymentMethod === 'ideal'">
        <b-row
          align-content="center"
          align-h="center"
          cols="3"
          class="mt-3 mb-3 py-3"
        >
          <b-col
            cols="12"
            :md="hasStepGuide ? 9 : 6"
            class="text-center"
          >
            <h1>
              <img
                :src="idealLogo"
                width="52px"
                alt="iDEAL"
              >
              iDEAL</h1>

            <b-img
              class="m-2"
              fluid
              :src="require('@/assets/images/icons/icon-error.svg')"
              alt="Error"
              width="125"
            />

            <h3 class="mb-3">
              Failed Payment!
            </h3>

            <div class="card">
              <div class="d-flex flex-row justify-content">
                <span class="py-1 pl-1">
                  Error:
                </span>
                <span class="text-danger p-1 text-left">
                  {{ payfastErrorMessage }}
                </span>
              </div>
            </div>

            <div
              class="action mt-2 d-flex flex-column flex-sm-row align-items-center action-wrap"
              :class="hasStepGuide ? 'justify-content-center' : 'justify-content-between'"
            >
              <btn-loading
                variant-convert="outline-dark"
                class="btn-sm-block"
                @click="handleRedirectRouter(tab='ideal')"
              >
                Recharge
              </btn-loading>

              <btn-loading
                v-if="!hasStepGuide"
                variant-convert="primary"
                class="btn-block mb-1 mt-1 ml-sm-2"
                @click="handleShowAdsAccount"
              >
                {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
              </btn-loading>
            </div>

          </b-col>
        </b-row>
      </b-container>

      <b-container v-if="paymentMethod === 'bancontact'">
        <b-row
          align-content="center"
          align-h="center"
          cols="3"
          class="mt-3 mb-3 py-3"
        >
          <b-col
            cols="12"
            :md="hasStepGuide ? 9 : 6"
            class="text-center"
          >
            <h1>
              <img
                :src="bancontactLogo"
                width="52px"
                alt="Bancontact"
              >
              Bancontact</h1>

            <b-img
              class="m-2"
              fluid
              :src="require('@/assets/images/icons/icon-error.svg')"
              alt="Error"
              width="125"
            />

            <h3 class="mb-3">
              Failed Payment!
            </h3>

            <div class="card">
              <div class="d-flex flex-row justify-content">
                <span class="py-1 pl-1">
                  Error:
                </span>
                <span class="text-danger p-1 text-left">
                  {{ payfastErrorMessage }}
                </span>
              </div>
            </div>

            <div
              class="action mt-2 d-flex flex-column flex-sm-row align-items-center action-wrap"
              :class="hasStepGuide ? 'justify-content-center' : 'justify-content-between'"
            >
              <btn-loading
                variant-convert="outline-dark"
                class="btn-sm-block"
                @click="handleRedirectRouter(tab='bancontact')"
              >
                Recharge
              </btn-loading>

              <btn-loading
                v-if="!hasStepGuide"
                variant-convert="primary"
                class="btn-block mb-1 mt-1 ml-sm-2"
                @click="handleShowAdsAccount"
              >
                {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
              </btn-loading>
            </div>

          </b-col>
        </b-row>
      </b-container>

      <div
        v-else-if="paymentMethod === 'BUSD'"
        class="container-box py-3"
      >
        <div class="header text-center px-2 pb-1">
          <img
            alt="success"
            :src="require('@/assets/images/pages/payment/ic-payment-failure.svg')"
          >
          <h3 class="my-1">
            Topup Failed!
          </h3>
          <p>Your transaction cannot be completed.</p>
        </div>
        <div class="card">
          <div class="p-2">
            <span class="">
              Error:
            </span>
            <span class="text-danger">
              {{ errorMessage }}
            </span>
          </div>
        </div>
        <div class="action mt-2 d-flex">
          <btn-loading
            class="font-14"
            variant-convert="primary"
            pill
            block
            @click="$router.push('/payment/add-fund?tab=busd')"
          >
            Recharge
          </btn-loading>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="danger" />
    </div>
  </div>
</template>

<script>
import {
  BContainer, BRow, BCol, BImg, BSpinner,
} from 'bootstrap-vue'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BtnLoading,
    BContainer,
    BRow,
    BCol,
    BImg,
    BSpinner,
  },

  mixins: [stepGuideMixin],

  data() {
    return {
      errorMessage: this.$route.query.error || '',
      payfastErrorMessage: this.$route.query.message || '',
      paymentMethod: this.$route.query.paymentMethod || '',
      listPaymentMethod: ['payoneer', 'airWallexAuto', 'payfast', 'localBank', 'ideal', 'bancontact'],
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    payoneerLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/payoneer.svg')
    },
    airwallexLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/ic-airwallex.svg')
    },
    payfastLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/ic-payfast.jpg')
    },
    localBankLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/ic-local-bank.png')
    },
    idealLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/ic-ideal.svg')
    },
    bancontactLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/ic-bancontact.svg')
    },
    hasUserData() {
      return Object.keys(this.user.data).length > 0
    },
  },

  created() {
    if (this.paymentMethod !== 'BUSD' && !this.listPaymentMethod.includes(this.paymentMethod)) {
      this.$router.push('/payment/add-fund')
    }
  },

  methods: {
    handleRedirectRouter(tab) {
      this.$router.push(`/payment/add-fund?tab=${tab}`)
    },

    handleShowAdsAccount() {
      this.$router.history.push('/my-ads-account')
    },
  },
}
</script>

<style lang="scss" scoped>
.container-box {
  max-width: 340px;
  margin: 0 auto;
}

.btn-recharge {
  border-radius: 100px;
  padding: 11px 20px;
}
</style>
